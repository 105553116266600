import React, { useState } from 'react'
import { ImagePlacementTask } from 'data-models/task-models'
import infoImage from 'assets/images/information-circle.svg'
import {
  Flex,
  Text,
  Image,
  VStack,
  Box,
  HStack,
  Heading,
  Center,
} from '@chakra-ui/react'
import ImagePlacementBase from './ImagePlacementBase'
import ImagePlacementItems from './ImagePlacementItems'
import TaskTag from '../TaskTag'
import CheckboxCircle from '../CheckboxAnswerGroup/CheckboxCircle'
import { t } from 'i18next'

export default function ImagePlacementTaskCmp(props: { task: any }) {
  const currentTask = props.task
  const task = currentTask as ImagePlacementTask
  const [checked, setChecked] = useState(false)

  return (
    <Flex direction='column' h='full' top={10} w='100%'>
      <Flex
        direction='column'
        align='stretch'
        p='5'
        h='full'
        borderRadius='md'
        bg='#773FFD'
      >
        <Box>
          <TaskTag text={task.description} />
          <Heading
            as='h1'
            fontSize='xl'
            fontWeight='semibold'
            mt='3'
            lineHeight='1'
            color={'white'}
          >
            {task.title}
          </Heading>
          <Text fontWeight='semibold' mt='3' color={'white'}>
            {task.subtitle}
          </Text>
        </Box>

        <Center w='full' userSelect='none' py='5px' flex='1' gridGap='5px'>
          <ImagePlacementBase task={task} />
          <ImagePlacementItems task={task} />
        </Center>

        {/* <HStack
          key={'7'}
          cursor='pointer'
          w='100%'
          p='15'
          pb='30'
          onClick={() => setChecked(!checked)}
        >
          <CheckboxCircle checked={checked} />
          <Text>{t('Child_Not_Participate')}</Text>
        </HStack> */}

        <VStack
          pb={'20px'}
          pt='1'
          w='full'
          align='stretch'
          borderTop='1px solid #352EB1'
        >
          <HStack lineHeight='1.5'>
            <Image src={infoImage} />
            <Text fontSize='sm' color={'white'}>{task.taskInstructions}</Text>
          </HStack>
        </VStack>
      </Flex>
    </Flex>
  )
}
