import React, { useEffect, useState } from 'react'
import {
  ImageSelectionAnswer,
  ImageSelectionTask,
  TaskType,
} from 'data-models/task-models'
import TaskTag from './TaskTag'
import {
  Flex,
  Text,
  Center,
  Image,
  VStack,
  Box,
  HStack,
  Heading,
  AspectRatio,
  GridItem,
} from '@chakra-ui/react'
import infoImage from 'assets/images/information-circle.svg'
import CheckIcon from './CheckIcon'
import { apiBase } from 'services/config'
import { isIOS } from 'react-device-detect'
import CheckboxImageAnswerGroup from './CheckboxAnswerGroup/CheckboxImageAnswerGroup'
import VideoPlayer from './VideoTask/VideoPlayer'

export default function ImageSelectionTaskCmp(props: { task: any }) {
  const isFullscreen = true

  const currentTask = props.task
  const [task, setTask] = useState(currentTask as ImageSelectionTask)

  function onSelectedChange(changed: ImageSelectionAnswer['id']) {
    const newOptionsState = task.answerOptions.map(ao => ({
      ...ao,
      checked: ao.id === changed ? !ao.checked : ao.checked,
    }))

    const checkImagesSelected = newOptionsState.filter(
      x => x.checked && x.type === TaskType.ImageSelection,
    )
    newOptionsState.map(obj => {
      if (
        checkImagesSelected.length > 0 &&
        obj.type === TaskType.Checkbox &&
        obj.checked
      ) {
        obj.checked = false
      }
      return obj
    })
    setTask(v => ({ ...v, ...{ answerOptions: newOptionsState } }))
  }

  const onAnswerChange = (answers: ImageSelectionAnswer[]) => {
    const checkCheckboxSelected = answers.filter(
      x => x.checked && x.type === TaskType.Checkbox,
    )
    answers.map(obj => {
      if (
        checkCheckboxSelected.length > 0 &&
        obj.type === TaskType.ImageSelection &&
        obj.checked
      ) {
        obj.checked = false
      }
      return obj
    })

    setTask(v => ({ ...v, ...{ answerOptions: answers } }))
  }

  useEffect(() => {}, [task])

  return (
    <Flex direction='column' h='full' top={10} w='100%'>
      <Flex
        direction='column'
        align='stretch'
        p='5'
        h='full'
        borderRadius='md'
        bg='#773FFD'
      >
        <Box>
          <TaskTag text={task.description} />
          <Heading
            as='h1'
            fontSize='xl'
            fontWeight='semibold'
            mt='3'
            lineHeight='1'
            color={'white'}
          >
            {task.title}
          </Heading>
          <Text fontWeight='semibold' mt='3' color={'white'}>
            {task.subtitle}
          </Text>
        </Box>

        <Flex
          h='full'
          w='full'
          maxW='full'
          justify='center'
          align='center'
          flexDirection='column'
        >
          <HStack
            spacing='5'
            justify='center'
            pt='20px'
            w={
              task.imageUrls?.length > 1 || task.videoUrls?.length > 1
                ? '100%'
                : '55%'
            }
            h={
              task.imageUrls?.length > 1 || task.videoUrls?.length > 1
                ? '50%'
                : '100%'
            }
          >
            {task.imageUrls?.map(url => (
              <AspectRatio
                key={url.toString()}
                ratio={2}
                borderRadius='xl'
                p={'6.25%'}
                w='full'
                overflow='hidden'
              >
                <Image
                  key={url}
                  src={apiBase + url}
                  borderRadius='xl'
                  w='full'
                />
              </AspectRatio>
            ))}
            {task.videoUrls?.map(url => (
              <GridItem key={url} h='full' w='full' minH='0'>
                <Center w='full' h='full'>
                  <VideoPlayer videoSrc={apiBase + url} />
                </Center>
              </GridItem>
            ))}
          </HStack>

          <Flex
            wrap='wrap'
            justify='center'
            w='100%'
            maxW={
              task.imageUrls?.length > 1 || task.videoUrls?.length > 1
                ? '100%'
                : isIOS
                ? 'md'
                : '100%'
            }
            h={
              task.imageUrls?.length > 1 || task.videoUrls?.length > 1
                ? '50%'
                : '100%'
            }
            align='center'
            py='5%'
            margin='10 auto'
          >
            {task.answerOptions.map(
              ao =>
                ao.type === TaskType.ImageSelection && (
                  <Center
                    key={ao.id}
                    boxSize='12vh'
                    bgColor='white'
                    borderRadius='xl'
                    borderWidth='4px'
                    mx='2'
                    my='2'
                    borderColor={ao.checked ? 'pink.500' : 'transparent'}
                    pos='relative'
                    cursor='pointer'
                    onClick={() => onSelectedChange(ao.id)}
                  >
                    {ao.checked && (
                      <Center
                        pos='absolute'
                        left='1'
                        top='1'
                        boxSize='5'
                        bgColor='pink.500'
                        borderRadius='full'
                      >
                        <CheckIcon color='white' />
                      </Center>
                    )}
                    <Image
                      src={apiBase + ao.imageUrl}
                      borderRadius='xl'
                      maxH='100%'
                      maxW='100%'
                    />
                  </Center>
                ),
            )}
          </Flex>
        </Flex>
{/* 
        <Box my='5px'>
          {task.answerOptions.map(
            ch =>
              ch.type === TaskType.Checkbox && (
                <Center
                  key={ch.id}
                  mx='2'
                  my='2'
                  pos='relative'
                  width='40%'
                  // marginBottom="-40px"
                >
                  <CheckboxImageAnswerGroup
                    options={task.answerOptions}
                    onChange={onAnswerChange}
                  />
                </Center>
              ),
          )}
        </Box> */}

        {/* <HStack
          key={'7'}
          cursor='pointer'
          w='100%'
          p='15'
          pb='30'
          onClick={() => setChecked(!checked)}
        >
          <CheckboxCircle checked={checked} />
          <Text>Barnet deltar inte</Text>
        </HStack> */}
        <VStack
          pb={isFullscreen ? '20px' : '0'}
          pt='1'
          w='full'
          align='stretch'
          borderTop='1px solid #352EB1'
        >
          <HStack lineHeight='1.5'>
            <Image src={infoImage} />
            <Text fontSize='sm' color={'white'}>{task.taskInstructions}</Text>
          </HStack>
        </VStack>
      </Flex>
    </Flex>
  )
}
