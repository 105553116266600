export enum TaskType {
  Checkbox = 'checkbox_selection',
  Audio = 'audio',
  Video = 'video',
  VideoMulti = 'video_multi',
  ImagePlacement = 'image_placement',
  ImageSelection = 'image_selection',
  ImageSorting = 'image_sorting',
  LanguageAnalysis = 'language_analysis',
  ImageClick = 'image_click',
}

// export const TaskTypeLabel: Record<TaskType, string> = {
//   audio: 'Audio',
//   checkbox_selection: 'Val av kryssruta',
//   image_placement: 'Bildplacering',
//   image_selection: 'Bildval',
//   image_sorting: 'Bildsortering',
//   video: 'Video',
//   video_multi: 'Video multi',
// }
// export const TaskTypeLabelEn: Record<TaskType, string> = {
//   audio: 'Audio',
//   checkbox_selection: 'Check box selection',
//   image_placement: 'Image placement',
//   image_selection: 'Image selection',
//   image_sorting: 'Image sorting',
//   video: 'Video',
//   video_multi: 'Video multi',
// }
export const TaskTypeLabel: Record<TaskType, string> = {
  audio: 'Audio',
  checkbox_selection: 'Bild',
  image_placement: 'Bild',
  image_selection: 'Video',
  image_sorting: 'Bild',
  video: 'Video',
  video_multi: 'Video',
  language_analysis: 'Audio',
  image_click:'Bild'
}
export const TaskTypeLabelEn: Record<TaskType, string> = {
  audio: 'Audio',
  checkbox_selection: 'Picture',
  image_placement: 'Picture',
  image_selection: 'Video',
  image_sorting: 'Picture',
  video: 'Video',
  video_multi: 'Video',
  language_analysis: 'Audio',
  image_click:'Picture'
}
export const ResponseTypeLabel: Record<TaskType, string> = {
  audio: 'Audio',
  checkbox_selection: 'Verbal',
  image_placement: 'Icke-verbal',
  image_selection: 'Verbal',
  image_sorting: 'Icke-verbal',
  video: 'Verbal',
  video_multi: 'Verbal',
  language_analysis: 'Verbal',
  image_click:'Icke-verbal'
}
export const ResponseTypeLabelEn: Record<TaskType, string> = {
  audio: 'Audio',
  checkbox_selection: 'Verbal',
  image_placement: 'Non-verbal',
  image_selection: 'Verbal',
  image_sorting: 'Non-verbal',
  video: 'Verbal',
  video_multi: 'Verbal',
  language_analysis: 'Verbal',
  image_click:'Non-verbal'
}
export interface Task {
  id: string
  orderIndex: number
  type: TaskType
  title: string | object
  subtitle?: string
  taskInstructions: string
  description: string
  answerOptions: any
  criteria?: string
  categoryId?: string
  score?: string
  name?: string
  skillArea?: string
  knowledge?:string
}

export interface CheckboxTask extends Task {
  type: TaskType.Checkbox
  imageUrl?: string
  imageBorder?: string
  images?: { url: string; checked: boolean }[]
  answerOptions: CheckboxAnswer[]
  answerInstructions: string
}
export interface CheckboxAnswer {
  id: string
  type: TaskType.Checkbox
  text: string
  checked: boolean
  styleClass?: string
}

export interface AudioTask extends Task {
  type: TaskType.Audio
  audioUrl: string
  answerOptions: CheckboxAnswer[]
  answerInstructions: string
}

export interface VideoTask extends Task {
  type: TaskType.Video
  videoUrl: string
  answerOptions: CheckboxAnswer[]
  answerInstructions: string
}

export interface VideoMultiTask extends Task {
  type: TaskType.VideoMulti
  title: MultiTaskTitle
  videoUrl: string
  answerOptions: MultiTaskAnswer
  answerInstructions: string
}
export interface MultiTaskTitle {
  a: string
  b: string
}
export interface MultiTaskAnswer {
  a: CheckboxAnswer[]
  b: CheckboxAnswer[]
}

export interface PlacementPoint {
  id: string
  x: number
  y: number
}

export interface ImagePlacementTask extends Task {
  type: TaskType.ImagePlacement
  imageUrl: string
  placementPoints: PlacementPoint[]
  answerOptions: ImagePlacementAnswer[]
}
export interface ImagePlacementAnswer {
  type: TaskType.ImagePlacement
  imageUrl: string
  correctPoint: PlacementPoint['id']
  placedAtPoint: PlacementPoint['id'] | null
  styleClass?: string
  text?: string
  checked?: boolean
}

export interface ImageSelectionTask extends Task {
  type: TaskType.ImageSelection
  imageUrls: string[]
  videoUrls: string[]
  answerOptions: ImageSelectionAnswer[]
}
export interface ImageSelectionAnswer {
  id: string
  type: TaskType.ImageSelection | TaskType.Checkbox
  imageUrl: string
  checked: boolean
  text?: string
  styleClass?: string
}

export interface ImageClickingTask extends Task {
  type: TaskType.ImageClick
  imageUrls: string[]
  videoUrls: string[]
  answerOptions: ImageClickingAnswer[]
}
export interface ImageClickingAnswer {
  id: string
  type: TaskType.ImageClick | TaskType.Checkbox
  imageUrl: string
  checked: boolean
  text?: string
  styleClass?: string
}

export interface ImageSortingTask extends Task {
  type: TaskType.ImageSorting
  placementPoints: PlacementPoint[]
  imageUrl?: string
  images: []
  answerOptions: ImagePlacementAnswer[]
  // answerInstructions: string
}

export interface LanguageAnalysisTask extends Task {
  type: TaskType.LanguageAnalysis,
  imageUrl: string,
}