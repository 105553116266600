import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel, TabPanels } from '@chakra-ui/react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { ResultsOverview } from './Results'
import { UserRole } from 'data-models'
import { useUser } from 'providers/UserProvider'
import { Comments } from './Comments'
import { FollowupOverview } from './Followups/FollowupOverview'
import { useTranslation } from 'react-i18next'

export const Overview = () => {
  const { t } = useTranslation()

  const { user } = useUser()
  const history = useHistory()

  const { pathname } = useLocation()

  const [activeTabIndex, setActiveTabIndex] = useState<number>(() => {
    switch (true) {
      case pathname.includes('comments'):
        return 2
      case pathname.includes('overview-results'):
        return 1
      case pathname.includes('overview-followups'):
      default:
        return 0
    }
  })

  const canSeeDashboard = [
    UserRole.SUPERADMIN,
    UserRole.ADMIN,
    UserRole.PRINCIPAL,
    UserRole.TEACHER
  ].includes(user.role)

  if (!canSeeDashboard) {
    history.push('/statistic')
  }

  return (
    <Tabs
      variant='line'
      style={{ outline: 'none' }}
      isLazy
      onChange={(tab: any) => setActiveTabIndex(tab)}
      defaultIndex={activeTabIndex}
    >
      <TabList minH='45px' py='0' px='8' w='full'>
        <Tab id='Dashboard' as={Link} to='/overview-followups'>
          {t('Overview_Task')}
        </Tab>
        <Tab
          id='List'
          as={Link}
          to={{
            pathname: '/overview-results',
          }}
        >
          {t('Overview_Response')}
        </Tab>
        <Tab
          id='Comments'
          as={Link}
          to={{
            pathname: '/overview-comments',
          }}
        >
          {t('Overview_Comments')}
        </Tab>
      </TabList>

      <TabPanels px='8' mt='7'>
        <TabPanel>
          <FollowupOverview />
        </TabPanel>
        <TabPanel>
          <ResultsOverview />
        </TabPanel>
        <TabPanel>
          <Comments />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}
