import React from 'react'
import {
  Flex,
  Text,
  Button,
  HStack,
  Spinner,
  SimpleGrid,
} from '@chakra-ui/react'
import CustomSelect from 'common/CustomSelect'
import { Followup } from 'data-models/followup'
import { useFilters } from 'providers/FilterProvider'
import { Area } from 'data-models/area'

import { useUser } from 'providers/UserProvider'
import { useFollowups } from 'services/followupService'
import {
  defaultFilter,
  ResultsStatsFilterType,
  SelectOption,
} from 'data-models'
import { useAreas } from 'services/areaService'
import { usePreschoolList } from 'services/preschoolService'
import { useTranslation } from 'react-i18next'

interface ResultsFilterProps {
  filter: ResultsStatsFilterType
  onFilterChange: (filter: Partial<ResultsStatsFilterType>) => void
  isDisabled?: boolean
}

export const ResultsFilter = ({
  filter,
  onFilterChange,
  isDisabled = false,
}: ResultsFilterProps) => {
  const { user } = useUser()
  const { setCurrentFollowupId } = useFilters()
  const { t } = useTranslation()

  const { response: areaOptions } = useAreas({
    organizationId: user.organizationId,
  })

  const {
    response: { preschools: preschoolOptions },
  } = usePreschoolList({ organizationId: user.organizationId })

  const { currentFollowupId } = useFilters()

  const filteredPreschoolOptions =
    filter.areaIds.length > 0
      ? preschoolOptions.filter(preschool =>
          filter.areaIds.includes(preschool.areaId || ''),
        )
      : preschoolOptions

  const finalFilteredPreschoolOptions = filteredPreschoolOptions.map(
    preschool => {
      return {
        label: preschool.preschoolName || '',
        value: preschool.preschoolId || '',
      }
    },
  )

  const {
    loading: filtersLoading,
    response: { data: followups },
  } = useFollowups({ organizationId: user.organizationId })

  const followupOptions = followups.map(x => {
    return {
      id: x.id,
      name: x.name,
      startDate: x.startDate,
      endDate: x.endDate,
    }
  })

  const resetFilter = () => {
    onFilterChange({
      ...defaultFilter,
      followupId: currentFollowupId,
    })
  }

  const onFollowupChange = (selectedFollowup: Followup) => {
    setCurrentFollowupId(selectedFollowup.id)
    onFilterChange({
      followupId: selectedFollowup.id,
      areaIds: [],
      preschoolIds: [],
      pageNumber: 1,
    })
  }

  const onAreaChange = (areas?: Area[]) => {
    const areaIds = areas ? areas?.map(area => area.id) : []
    const preschools = preschoolOptions.filter(preschool =>
      filter.preschoolIds.includes(preschool.preschoolId || ''),
    )

    const preschoolIdsBelongingToAreas = preschools
      .filter(preschool => areaIds.includes(preschool.areaId || ''))
      .map(preschool => preschool.preschoolId!)

    onFilterChange({
      areaIds,
      preschoolIds: preschoolIdsBelongingToAreas,
      pageNumber: 1,
    })
  }

  const onPreschoolChange = (options?: SelectOption[]) => {
    onFilterChange({
      preschoolIds: options ? options.map(option => option.value) : [],
      pageNumber: 1,
    })
  }


  return (
    <>
      {filtersLoading ? (
        <Flex justifyContent={'center'} w='full' h='full'>
          <Spinner size='lg' />
        </Flex>
      ) : (
        <HStack
          alignItems='flex-start'
          justifyContent='space-between'
          id='statisticsFilter'
          w='full'
        >
          <HStack spacing='7'>
            <HStack alignItems='center' spacing='3'>
              <SimpleGrid columns={1} row={2} gap={0}>
                <Text
                  width={'100%'}
                  height={'20px'}
                  fontSize='12px'
                  color='#5A738B'
                >
                  {t('FollowupFilter_FollowUp_Name')}{' '}
                </Text>
                <CustomSelect<Followup>
                  name='followups'
                  value={followupOptions.find(
                    followup => followup.id === filter.followupId,
                  )}
                  options={followupOptions}
                  onChange={onFollowupChange}
                  className='bold-value'
                  searchable={true}
                  isMulti={false}
                  placeholder={t('ResultFilter_Choose_FollowUp')}
                  minWidth='250px'
                  isDisabled={isDisabled}
                  maxWidth='250px'
                />
              </SimpleGrid>
            </HStack>
            <HStack alignItems='center' spacing='3'>
              <SimpleGrid columns={1} row={2} gap={0}>
                <Text
                  width={'100%'}
                  height={'20px'}
                  fontSize='12px'
                  color='#5A738B'
                >
                  {t('ResultFilter_Area')}
                </Text>
                <CustomSelect
                  name='areas'
                  value={areaOptions.filter(area =>
                    filter.areaIds.includes(area.id),
                  )}
                  options={areaOptions.sort((a, b) =>a.name.localeCompare(b.name, 'sv'))}
                  onChange={onAreaChange}
                  searchable={true}
                  isMulti={true}
                  minWidth='270px'
                  maxHeight='38px'
                  placeholder={t('ResultFilter_Choose_Area')}
                  isDisabled={isDisabled}
                />
              </SimpleGrid>
            </HStack>
            <HStack alignItems='center' spacing='3'>
              <SimpleGrid columns={1} row={2} gap={0}>
                <Text
                  width={'100%'}
                  height={'20px'}
                  fontSize='12px'
                  color='#5A738B'
                >
                  {t('ResultFilter_Preschool')}{' '}
                </Text>
                <CustomSelect
                  name='preschools'
                  value={finalFilteredPreschoolOptions.filter(preschool =>
                    filter.preschoolIds.includes(preschool.value),
                  )}
                  options={finalFilteredPreschoolOptions.sort((a, b) =>a.label.localeCompare(b.label, 'sv'))}
                  onChange={onPreschoolChange}
                  searchable={true}
                  isMulti={true}
                  minWidth='250px'
                  placeholder={t('ResultFilter_Choose_Preschool')}
                  isDisabled={isDisabled}
                />
              </SimpleGrid>
            </HStack>

            {!isDisabled && (
              <SimpleGrid columns={1} row={2} gap={0}>
                <Text width={'100%'} height={'20px'} fontSize='12px'></Text>
                <Button variant='ghost' onClick={resetFilter}>
                  {t('ResultFilter_Reset')}
                </Button>
              </SimpleGrid>
            )}
          </HStack>
        </HStack>
      )}
    </>
  )
}
