import React from 'react'

import { VStack } from '@chakra-ui/react'
import { Redirect } from 'react-router-dom'
import { CommentsFilter, CommentsTable } from './components'
import { useComments } from './hooks/useComments'

export const Comments = () => {
  const {
    filter,
    handleFilterChange,
    isAllowedToView,
    isLoading,
    response,
    handleExport,
    filtersLoading,
  } = useComments()

  return (
    <VStack w='full' spacing='12'>
      <CommentsFilter
        filtersLoading={filtersLoading}
        canExport={response?.data?.length > 0}
        handleExport={handleExport}
        filter={filter}
        onFilterChange={handleFilterChange}
      />
      <CommentsTable
        loading={isLoading || filtersLoading}
        response={response}
        onFilterChange={handleFilterChange}
        filter={filter}
      />
    </VStack>
  )
}
