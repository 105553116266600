import React from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {
  Box,
  Text,
  Flex,
  Image,
  Button,
  HStack,
  VStack,
  Spinner,
  SimpleGrid,
} from '@chakra-ui/react'
import calendarIcon from '../../../../../assets/images/ico-calender.svg'
import { useTranslation } from 'react-i18next'
import { useCommentsFilter } from '../hooks'
import { CommentsFilterType } from '../types'
import CustomSelect from 'common/CustomSelect'

interface CommentsFilterProps {
  canExport: boolean
  handleExport: () => void
  filter: CommentsFilterType
  onFilterChange: (newFilter: Partial<CommentsFilterType>) => void
  filtersLoading: boolean
}

export const CommentsFilter = ({
  filter,
  canExport,
  handleExport,
  filtersLoading,
  onFilterChange,
}: CommentsFilterProps) => {
  const {
    areaOptions,
    onAreaChange,
    followupOptions,
    onEndDateChange,
    onFollowupChange,
    preschoolOptions,
    onPreschoolChange,
    onStartDateChange,
    resetFilter,
  } = useCommentsFilter({ onFilterChange, filter })
  const { t } = useTranslation()
  const finalFilteredPreschoolOptions = preschoolOptions.map(preschool => {
    return {
      label: preschool.preschoolName || '',
      value: preschool.preschoolId || '',
    }
  })

  return (
    <>
      {filtersLoading ? (
        <Flex pt='20' justifyContent={'center'} w='full' h='full'>
          <Spinner size='lg' />
        </Flex>
      ) : (
        <VStack w='full'>
          <Box w='full'>
            <HStack w='full' h='full'>
              <HStack>
                <SimpleGrid columns={1} row={12} gap={0}>
                  <Text
                    width={'100%'}
                    height={'20px'}
                    fontSize='12px'
                    color='#5A738B'
                    textOverflow='ellipsis'
                    whiteSpace='nowrap'
                    overflow='hidden'
                  >
                    {t('FollowupFilter_FollowUp_Name')}{' '}
                  </Text>
                  <CustomSelect
                    name='followups'
                    value={followupOptions.find(
                      followup => followup.id === filter.followupId,
                    )}
                    options={followupOptions}
                    onChange={onFollowupChange}
                    className='bold-value'
                    searchable={true}
                    isMulti={false}
                    minWidth='250px'
                    placeholder={t('ResultFilter_Choose_FollowUp')}
                    maxWidth='250px'
                    maxHeight='38px'
                  />
                </SimpleGrid>
              </HStack>
              <HStack>
                <SimpleGrid columns={1} row={2} gap={0}>
                  <Text
                    width={'100%'}
                    height={'20px'}
                    fontSize='12px'
                    color='#5A738B'
                  >
                    {t('ResultFilter_Area')}
                  </Text>
                  <CustomSelect
                    name='areas'
                    value={areaOptions.filter(area =>
                      filter.areaIds.includes(area.id),
                    )}
                    options={areaOptions.sort((a, b) =>a.name.localeCompare(b.name, 'sv'))}
                    onChange={onAreaChange}
                    searchable={true}
                    isMulti={true}
                    minWidth='250px'
                    placeholder={t('ResultFilter_Choose_Area')}
                  />
                </SimpleGrid>
              </HStack>
              <HStack>
                <SimpleGrid columns={1} row={2} gap={0}>
                  <Text
                    width={'100%'}
                    height={'20px'}
                    fontSize='12px'
                    color='#5A738B'
                  >
                    {t('ResultFilter_Preschool')}{' '}
                  </Text>
                  <CustomSelect
                    name='preschools'
                    value={finalFilteredPreschoolOptions.filter(preschool =>
                      filter.preschoolIds.includes(preschool.value),
                    )}
                    options={finalFilteredPreschoolOptions.sort((a, b) =>a.label.localeCompare(b.label, 'sv'))}
                    onChange={onPreschoolChange}
                    searchable={true}
                    isMulti={true}
                    minWidth='250px'
                    placeholder={t('ResultFilter_Choose_Preschool')}
                  />
                </SimpleGrid>
              </HStack>
              <HStack>
                <SimpleGrid columns={1} row={2} gap={0}>
                  <Text
                    width={'100%'}
                    height={'20px'}
                    fontSize='12px'
                    color='#5A738B'
                    minWidth='250px'
                  >
                    {t('FollowupTable_Start_date')}
                  </Text>
                  <div
                    className='position-relative'
                    style={{ backgroundColor: 'white' }}
                  >
                    <label>
                      <div>
                        <DatePicker
                          isClearable
                          clearButtonClassName='right-padding'
                          dateFormat='yyyy-MM-dd'
                          selected={filter.startDate}
                          autoComplete='off'
                          className='date-picker sm'
                          maxDate={new Date()}
                          onChange={onStartDateChange}
                          placeholderText={t('CommentFilter_Choose_Date')}
                        />
                      </div>
                      <div className='cal-icon sm'>
                        <Image src={calendarIcon} />
                      </div>
                    </label>
                  </div>
                </SimpleGrid>
              </HStack>
            </HStack>
          </Box>
          <Box w='full' pt={3}>
            <HStack w='full' h='full'>
              <HStack>
                <SimpleGrid columns={1} row={2} gap={0}>
                  <Text
                    width={'100%'}
                    height={'20px'}
                    fontSize='12px'
                    color='#5A738B'
                    minWidth='250px'
                  >
                    {t('FollowupTable_End_date')}
                  </Text>
                  <div
                    className='position-relative'
                    style={{ backgroundColor: 'white' }}
                  >
                    <label>
                      <div>
                        <DatePicker
                          isClearable
                          clearButtonClassName='right-padding'
                          dateFormat='yyyy-MM-dd'
                          selected={filter.endDate}
                          autoComplete='off'
                          className='date-picker sm'
                          maxDate={new Date()}
                          onChange={onEndDateChange}
                          placeholderText={t('CommentFilter_Choose_Date')}
                        />
                      </div>
                      <div className='cal-icon sm'>
                        <Image src={calendarIcon} />
                      </div>
                    </label>
                  </div>
                </SimpleGrid>
              </HStack>

              <HStack>
                <SimpleGrid columns={1} row={2} gap={0}>
                  <Text width={'100%'} height={'20px'} fontSize='12px'></Text>
                  <HStack>
                    <Button
                      w='fit-content'
                      variant='ghost'
                      onClick={resetFilter}
                    >
                      {t('ResultFilter_Reset')}
                    </Button>
                    <Button
                      variant={'outline'}
                      isDisabled={!canExport}
                      onClick={handleExport}
                      w='fit-content'
                    >
                      {t('CommentFilter_Export')}
                    </Button>
                  </HStack>
                </SimpleGrid>
              </HStack>
            </HStack>
          </Box>
        </VStack>
      )}
    </>
  )
}
