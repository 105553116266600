import { toInteger } from 'lodash-es'
import { Followup } from 'data-models'
import axiosAPIInstance from '../services/api'
import { FollowupDTO, FollowupsResponse } from 'data-models/followup'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { useToast } from '@chakra-ui/react'
import { t } from 'i18next'

interface UseFollowupProps {
  followupId: string
  organizationId: string
}

const getFollowup = ({ followupId, organizationId }: UseFollowupProps) => {
  return axiosAPIInstance
    .get(`/followups/${organizationId}/${followupId}`)
    .then(({ data }) => {
      return data as Followup
    })
}

export const useFollowup = ({
  organizationId,
  followupId,
}: UseFollowupProps) => {
  const getFollowupQuery = useQuery({
    queryKey: ['followups', `${followupId}`],
    queryFn: () => getFollowup({ organizationId, followupId }),
    staleTime: 1000 * 60 * 10,
    enabled: !!followupId,
  })

  return getFollowupQuery
}

interface UseFollowupsProps {
  name?: string
  status?: string
  pageSize?: number
  pageNumber?: number
  academicYear?: string
  organizationId: string
}

const getFollowups = ({ organizationId, ...rest }: UseFollowupsProps) => {
  return axiosAPIInstance
    .get('/followups/paginated', {
      params: { organizationId: organizationId, ...rest },
    })
    .then(({ data }) => {
      return data as FollowupsResponse
    })
}

export const useFollowups = ({
  organizationId,
  ...rest
}: UseFollowupsProps) => {
  const getFollowupsQuery = useQuery({
    queryKey: ['followups', { ...rest }],
    queryFn: () => getFollowups({ organizationId, ...rest }),
    staleTime: 1000 * 60 * 10,
  })

  const {
    data: response = {
      data: [],
      totalCount: 0,
    },
    error,
    isFetching: loading,
  } = getFollowupsQuery

  return {
    error,
    loading,
    response,
  }
}

interface UseEditFollowProps {
  id: string
  newFollowup: FollowupDTO
}

const editFollowup = ({ id, newFollowup }: UseEditFollowProps) =>
  axiosAPIInstance.put(`/followups/${id}`, {
    ...newFollowup,
    organizationId: toInteger(newFollowup.organizationId),
  })

export const useEditFollowup = () => {
  const queryClient = useQueryClient()
  const editFollowupMutation = useMutation({
    mutationFn: ({ id, newFollowup }: UseEditFollowProps) =>
      editFollowup({ id, newFollowup }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['followups'])
      queryClient.invalidateQueries(['followups', `${variables.id}`], {
        exact: true,
      })
    },
  })

  return editFollowupMutation
}

interface UseCreateFollowProps {
  newFollowup: FollowupDTO
}

const createFollowup = ({ newFollowup }: UseCreateFollowProps) => {
  return axiosAPIInstance.post(`/followups`, {
    ...newFollowup,
    organizationId: toInteger(newFollowup.organizationId),
  });
};

export const useCreateFollowup = () => {
  const toast = useToast();

  const queryClient = useQueryClient()
  const createFollowupMutation = useMutation({
    mutationFn: ({ newFollowup }: UseCreateFollowProps) =>
      createFollowup({ newFollowup }),
    onSuccess: () => {
      queryClient.invalidateQueries(['followups'])
    },
    onError: (error: any) => {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors[0].code
        // Customize the error message based on the name already exists
        if (errorMessage === "1003") {
          // Display a user-friendly message to the user
          toast({
            title: `${t('followup_with_same_name_already_exists')}`,
            isClosable: true,
            duration: 5000,
            status: 'error',
          })
        } else {
          // Handle other error cases if needed
          toast({
            title: `${t('An_error_occurred_during_followUp_creation')}`,
            isClosable: true,
            duration: 3000,
            status: 'error',
          })
        }
      } else {
        // Handle unexpected error structure or other cases
        toast({
          title: `${t('An_unexpected_error_occurred')}`,
          isClosable: true,
          duration: 3000,
          status: 'error',
        })
      }
    },
  })
  return createFollowupMutation
}
interface UseDeleteFollowProps {
  id: string
}

const deleteFollowup = ({ id }: UseDeleteFollowProps) =>
  axiosAPIInstance.delete(`/followups/${id}`)

export const useDeleteFollowup = () => {
  const queryClient = useQueryClient()
  const deleteFollowupMutation = useMutation({
    mutationFn: ({ id }: UseDeleteFollowProps) => deleteFollowup({ id }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['followups'])
      queryClient.invalidateQueries(['followups', `${variables.id}`], {
        exact: true,
      })
    },
  })

  return deleteFollowupMutation
}
