import React, { useEffect, useState } from 'react'
import { CheckboxAnswer, VideoMultiTask } from 'data-models/task-models'
import TaskTag from '../TaskTag'
import {
  Box,
  Flex,
  Text,
  Image,
  VStack,
  HStack,
  Center,
  Heading,
} from '@chakra-ui/react'
import infoImage from 'assets/images/information-circle.svg'
import CheckboxAnswerGroup from '../CheckboxAnswerGroup/CheckboxAnswerGroup'
import VideoPlayer from './VideoPlayer'
import { apiBase } from 'services/config'

export default function VideoMultiTaskCmp(props: { task: any }) {
  const currentTask = props.task
  const [task, setTask] = useState(currentTask as VideoMultiTask)

  const onAnswerChangeA = (answers: CheckboxAnswer[]) => {
    const newAnswerOptions = {
      a: answers,
      b: task.answerOptions.b,
    }
    setTask(v => ({ ...v, ...{ answerOptions: newAnswerOptions } }))
  }
  const onAnswerChangeB = (answers: CheckboxAnswer[]) => {
    const newAnswerOptions = {
      a: task.answerOptions.a,
      b: answers,
    }
    setTask(v => ({ ...v, ...{ answerOptions: newAnswerOptions } }))
  }

  useEffect(() => {}, [task])

  return (
    <Flex direction='column' h='full' top={10} w='100%'>
      {/* <Flex direction="column" h="full"> */}
      <Flex
        direction='column'
        align='stretch'
        p='5'
        h='full'
        borderRadius='md'
        bg='#773FFD'
      >
        <Box>
          <TaskTag text={task.description} />
          {Object.values(task.title).map((title, index) => {
            return (
              <Heading
                key={index}
                as='h1'
                fontSize='xl'
                fontWeight='semibold'
                mt='3'
                lineHeight='1'
                color='white'
              >
                {title}
              </Heading>
            )
          })}
          <Text fontWeight='semibold' mt='3' color='white'>
            {task.subtitle}
          </Text>
        </Box>

        <Center m='5' w='66%' h='35%' flex='1' alignSelf='center'>
          <VideoPlayer videoSrc={apiBase + task.videoUrl} />
        </Center>

        <VStack
          pb={'25px'}
          pt='1'
          w='full'
          align='stretch'
          borderTop='1px solid #352EB1'
        >
          <HStack lineHeight='1.5'>
            <Image src={infoImage} />
            <Text fontSize='sm' color='white'>{task.taskInstructions}</Text>
          </HStack>
        </VStack>
      </Flex>

      <div style={{ height: '20px', backgroundColor: 'white' }}></div>

      <Flex direction='column' h='full' p='5' borderRadius='md' bg='#773FFD'>
        <HStack
          borderBottom='1px solid #352EB1'
          pt={'10px'}
          pb='10px'
          mb='10px'
          lineHeight='1.5'
        >
          <Image src={infoImage} />
          <Text fontSize='sm' color='white'>{task.answerInstructions}</Text>
        </HStack>
        <Flex direction='column' w='full' align='center' flex='1' pt='10px'>
          <Heading
            as='h1'
            fontSize='xl'
            fontWeight='semibold'
            mb='3'
            lineHeight='1'
            alignSelf='start'
            color='white'
          >
            {task.title.a}
          </Heading>
          <Flex direction='column' w='full' align='center' flex='1'>
            <CheckboxAnswerGroup
              options={task.answerOptions.a}
              onChange={onAnswerChangeA}
            />
          </Flex>
        </Flex>
        {/*       
      <HStack 
            pt="15">
        {task.answerOptions.b.map(o => (
          o.styleClass =="childParticipate" && (
          <HStack
            key={o.id}
            cursor="pointer"
            w="80%"
            p="15"
            bgColor={o.checked ? 'pink.500' : 'transparent'}
            
          >
            <CheckboxCircle checked={o.checked} />
            <Text>{o.text}</Text>
          </HStack>
          )
        ))}
      </HStack> */}
      </Flex>

      <div style={{ height: '20px', backgroundColor: 'white' }}></div>

      <Flex direction='column' h='full' p='5' borderRadius='md' bg='#773FFD'>
        <HStack
          borderBottom='1px solid #352EB1'
          pt={'10px'}
          pb='10px'
          mb='10px'
          lineHeight='1.5'
        >
          <Image src={infoImage} />
          <Text fontSize='sm'>{task.answerInstructions}</Text>
        </HStack>
        <Flex direction='column' w='full' align='center' flex='1' pt='10px'>
          <Heading
            as='h1'
            fontSize='xl'
            fontWeight='semibold'
            mb='3'
            lineHeight='1'
            alignSelf='start'
            color={'white'}
          >
            {task.title.b}
          </Heading>
          <Flex direction='column' w='full' align='center' flex='1' pt='5px'>
            <CheckboxAnswerGroup
              options={task.answerOptions.b}
              onChange={onAnswerChangeB}
            />
          </Flex>
        </Flex>

        {/* <HStack 
          pt="15">
      {task.answerOptions.b.map(o => (
        o.styleClass =="childParticipate" && (
        <HStack
          key={o.id}
          cursor="pointer"
          w="80%"
          p="15"
          bgColor={o.checked ? 'pink.500' : 'transparent'}
          
        >
          <CheckboxCircle checked={o.checked} />
          <Text>{o.text}</Text>
        </HStack>
        )
      ))}
    </HStack> */}
      </Flex>
    </Flex>
  )
}
