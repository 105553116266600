export type ProgressionStats = {
  followups: Followups[]
  tasks: ProgressionTasks[]
}
interface FollowupComparison {
  followupId: string;
  followupName: string;
  count: number;
  percentage: number;
}

interface FollowupGroup {
  groupName: string;
  followupGroupComparisons: FollowupComparison[];
}

export interface ChartData {
  followupGroups: FollowupGroup[];
}

export type ProgressionTask = {
  followups: Followups[]
  tasks: ProgressionTasks[]
}

export type ProgressionTasks = {
  answerCount: number
  averagePercent: number
  categoryId: string
  followups: Followups[]
  noAnswerCount: number
  taskId: string
  taskName: string
  taskTitle: string
  totalCount: number
  totalScore: number
}

export type Followups = {
  id: string
  name: string
  createdAt: string
  startDate: string
  answerCount: number
  noAnswerCount: number
  totalScore: number
  totalCount: number
  tasks: any[]
  averagePercent: number | string
}

export type SchoolYearOptions = {
  id: string | number
  name: string
}
export const ProgressionTypeLabel: Record<string, string> = {
  Uppföljning: 'Uppföljning',
  Aktivitet: 'Aktivitet',
}
export const ProgressionTypeLabelEn: Record<string, string> = {
  Uppföljning: 'Follow-up',
  Aktivitet: 'Activity',
}
export const ProgressionTypeLabelDe: Record<string, string> = {
  Uppföljning: 'Nachverfolgung',
  Aktivitet: 'Aktivität',
}