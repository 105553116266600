import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Grid,
  Flex,
  Input,
  Button,
  HStack,
  GridItem,
  FormLabel,
  FormControl,
} from '@chakra-ui/react'
import CustomSelect from 'common/CustomSelect'
import { useUser } from 'providers/UserProvider'
import { StatusLabels, StatusLabelsEn } from 'services/indicators'
import { FollowupOverviewFilterType } from '../types'
import { useFollowups } from 'services/followupService'
import { FollowupStatus, Option, UserRole } from 'data-models'
import { useTranslation } from 'react-i18next'

interface FollowupFilterProps {
  filter: FollowupOverviewFilterType
  onFilterChange: (filter: Partial<FollowupOverviewFilterType>) => void
}

export const FollowupFilter = ({
  filter,
  onFilterChange,
}: FollowupFilterProps) => {
  const {
    user: { organizationId, role },
  } = useUser()
  const {
    response: { data: followups },
  } = useFollowups({ organizationId })

  const history = useHistory()
  const { t, i18n } = useTranslation()
  const followupgridlist = followups.filter(f => f.tasks.length !== 0)

  const academicYears = [...new Set(followupgridlist.map(f => f.academicYear))]
  const academicYearOptions = academicYears.map(x => ({ value: x, name: x }))

  const followupStatuses = Object.values(FollowupStatus)
  const statusOptions = followupStatuses.map(x => ({
    value: x,
    name: StatusLabels[x],
  }))
  const statusOptionsEn = followupStatuses.map(x => ({
    value: x,
    name: StatusLabelsEn[x],
  }))
  const onFollowupSearchChange = e => {
    const name = e.target.value

    onFilterChange({
      name,
      pageNumber: 1,
    })
  }

  const onAcademicYearChange = (option?: Option) => {
    onFilterChange({
      academicYear: option?.value,
      pageNumber: 1,
    })
  }

  const onStatusChange = (option?: Option) => {
    onFilterChange({
      status: option?.value,
      pageNumber: 1,
    })
  }

  const isAdmin = [UserRole.ADMIN, UserRole.SUPERADMIN, UserRole.PRINCIPAL,UserRole.TEACHER].includes(role)
  return (
    <HStack h='full' justifyContent='space-between' w='full' pb='8'>
      <Grid gap={5} w='full' templateColumns='repeat(3, 1fr)'>
        <GridItem w='100%'>
          <FormControl>
            <FormLabel fontSize='12px' color='#5A738B'>
              {t('FollowupFilter_FollowUp_Name')}
            </FormLabel>
            <Input
              type='search'
              placeholder={t('FollowupFilter_Search')}
              name='Followups'
              _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
              focusBorderColor=''
              fontSize='14px'
              bgColor={'white'}
              color={'#1A2C3D'}
              border='1px'
              borderColor='#B1C5D9'
              h={'40px'}
              borderRadius={'8px'}
              value={filter.name}
              onChange={onFollowupSearchChange}
            />
          </FormControl>
        </GridItem>
        <GridItem w='100%'>
          <FormControl>
            <FormLabel fontSize='12px' color='#5A738B'>
              {t('FollowupFilter_School_year')}
            </FormLabel>
            <Box>
              <CustomSelect
                isClearable
                name='academicYear'
                className='bold-value'
                placeholder={t('FollowupFilter_Select_Academic_year')}
                value={academicYearOptions.filter(
                  option => option.value === filter.academicYear,
                )}
                options={academicYearOptions}
                valueKey='code'
                minWidth='200px'
                onChange={onAcademicYearChange}
              />
            </Box>
          </FormControl>
        </GridItem>
        <GridItem w={{base:'100%',lg:'275px'}}>
          <FormControl>
            <FormLabel fontSize='12px' color='#5A738B'>
              {t('FollowupFilter_Follow-up_status')}
            </FormLabel>
            {i18n.language === 'en' ? (
              <Box>
                <CustomSelect
                  isClearable
                  name='status'
                  className='bold-value'
                  placeholder={t('FollowupFilter_Select_follow-up_status')}
                  value={statusOptionsEn.filter(
                    option => option.value === filter.status,
                  )}
                  options={statusOptionsEn}
                  minWidth='220px'
                  onChange={onStatusChange}
                />
              </Box>
            ) : (
              <Box>
                <CustomSelect
                  isClearable
                  name='status'
                  className='bold-value'
                  placeholder={t('FollowupFilter_Select_follow-up_status')}
                  value={statusOptions.filter(
                    option => option.value === filter.status,
                  )}
                  options={statusOptions}
                  minWidth='220px'
                  onChange={onStatusChange}
                />
              </Box>
            )}
          </FormControl>
        </GridItem>
      </Grid>
      {isAdmin && (
        <Flex h='full' w='full' justify='flex-end' align='end' pt='4'>
          <Button
            style={{
              fontSize: '15px',
              backgroundColor: '#8A2BE2',
              color: 'white',
              height: '40px',
              width: '110px',
            }}
            onClick={() =>
              history.push({
                pathname: '/followup/create',
                state: {
                  activeView: 'NewFollowup',
                  headerText: 'Skapa uppföljning',
                  followupId: '',
                },
              })
            }
          >
            {t('FollowupFilter_Create')}
          </Button>
        </Flex>
      )}
    </HStack>
  )
}
