import React, { useEffect, useState } from 'react'
import { ImageSortingTask } from 'data-models/task-models'
import TaskTag from '../TaskTag'
import infoImage from 'assets/images/information-circle.svg'
import CheckboxCircle from '../CheckboxAnswerGroup/CheckboxCircle'
import {
  Flex,
  Text,
  Image,
  VStack,
  Box,
  HStack,
  Heading,
  Center,
} from '@chakra-ui/react'
import ImagePlacementAnswer from './ImagePlacementAnswer'
import ImagePlacementItems from './ImagePlacementItems'

export default function ImageSortingTaskCmp(props: { task: any }) {
  const [task, _setTask] = useState(props.task as ImageSortingTask)
  const [placedImages, setPlacedImages] = useState(task.images as string[])
  let placedImagesNew = task.images as string[]
  const [checked, setChecked] = useState(false)

  function onSelectedChange() {
    setChecked(!checked)
  }
  useEffect(() => {}, [checked])

  function findImg(img: string) {
    let found = false
    task.answerOptions.forEach(x => {
      if (x.placedAtPoint != null)
        if (img.includes(x.placedAtPoint)) found = true
    })
    return found
  }
  const onDrag = (_draggedItem: string, _placedItem: string) => {
    const newImages = [] as any
    task.images.forEach((a: string) => {
      if (findImg(a)) newImages.push('')
      else newImages.push(a)
    })
    placedImagesNew = newImages

    setPlacedImages(placedImagesNew)
  }
  // // let newOptions = [] as any
  // //   answerOptionsNew.forEach((a,index) =>
  // //     {
  // //       if (a.placedAtPoint != item)
  // //       newOptions.push(a)
  // //     }
  // //   )
  // //   setAnswerOptions(newOptions)
  //   // setTask(v => ({...v,...{answerOptions: newOptions}}))

  //   let placedItemUrl = ''
  //   let indexPlaced = -1
  //   if (placedItem != '')
  //     task.images.forEach((a : string,index) =>
  //     {
  //       if (a.includes(placedItem)){
  //         placedItemUrl = a
  //         indexPlaced = index
  //       }
  //     }
  //   )
  //   // if (placedItem == draggedItem) placedItemUrl = ''

  //   let newImages = [] as any
  //   placedImagesNew.forEach((a : string) =>
  //   {
  //     a.includes(draggedItem) ?
  //       newImages.push('')
  //     :
  //     newImages.push(a)
  //   }
  // )
  // if (indexPlaced != -1) newImages[indexPlaced] = placedItemUrl
  //
  //  }

  useEffect(() => {
    // onDrag('','')
  }, [placedImages, task])

  return (
    <Flex direction='column' h='full' top={10} w='100%'>
      <Flex
        direction='column'
        align='stretch'
        p='5'
        h='full'
        borderRadius='md'
        bg='#773FFD'
        className='print-flex'
      >
        <Box>
          <TaskTag text={task.description} />
          <Heading
            as='h1'
            fontSize='xl'
            fontWeight='semibold'
            mt='3'
            lineHeight='1'
            color={'white'}
          >
            {task.title}
          </Heading>
          <Text fontWeight='semibold' mt='3' color={'white'}>
            {task.subtitle}
          </Text>
        </Box>
        <Center w='full' userSelect='none' py='20px'>
          <ImagePlacementItems images={placedImages} task={task}/>
        </Center>
        <Center w='full' userSelect='none' py='20px'>
          <ImagePlacementAnswer task={task} onDrag={onDrag} />
        </Center>
       

        {task.answerOptions.map(
          o =>
            o.styleClass === 'childParticipate' && (
              <HStack
                key={'7'}
                cursor='pointer'
                w='100%'
                p='15'
                pb='30'
                // bgColor={o.checked ? 'pink.500' : 'transparent'}
                onClick={() => onSelectedChange()}
              >
                <CheckboxCircle checked={checked} />
                <Text>{o.text}</Text>
              </HStack>
            ),
        )}

        <VStack
          pb={'20px'}
          pt='10'
          w='full'
          align='stretch'
          borderTop='1px solid #352EB1'
        >
          <HStack lineHeight='1.5'>
            <Image src={infoImage} />
            <Text fontSize='sm' color={'white'}>{task.taskInstructions}</Text>
          </HStack>
        </VStack>
      </Flex>
    </Flex>
  )
}
