import React, { useState, useEffect } from 'react'
import DatePicker from 'react-datepicker'
import { Controller } from 'react-hook-form'
import 'react-datepicker/dist/react-datepicker.css'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import iconsort from 'assets/images/Icon-reorder.svg'

import calendarIcon from '../../../assets/images/ico-calender.svg'

import {
  Td,
  Th,
  Tr,
  Box,
  Text,
  Flex,
  Grid,
  Input,
  Image,
  Table,
  Tbody,
  Thead,
  HStack,
  VStack,
  GridItem,
  Textarea,
  TableContainer,
  Badge,
  TagLabel,
  Tag,
  useDisclosure,
  Button,
  Collapse,
  Select,
  Checkbox,
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
import { FormWrapper, MessageFormWrapper } from 'common/Form'
import { useFollowupDetails } from './hooks'
import CustomSelect from 'common/CustomSelect'
import TaskHelpButtonComponent from './TaskHelpButtonComponent'
import TaskHelpComponent from './TaskHelpComponent'
import {
  Followup,
  FollowupStatus,
  ResponseTypeLabel,
  ResponseTypeLabelEn,
  TaskTypeLabel,
  TaskTypeLabelEn,
} from 'data-models'
import TaskPreview from 'pages/MainTabs/OverviewTab/TaskPreview/taskPreview'
import { CheckboxIsExportable } from 'common/CheckboxIsExportable'
import { StatisticsIcon } from 'common/Icons'
import { StatsContainer } from 'pages/SuperAdminSettings/common'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
interface Task {
  id: string
  // Add other properties if needed
}
interface FollowupDetailsProps {
  isEditable: boolean
  followup: Followup
  isEditEndDate?: boolean
  isPrint ?: boolean
}

export const FollowupDetails = ({
  isEditable,
  followup, isEditEndDate
}: FollowupDetailsProps) => {
  const { id, numberOfParticipants, numberOfSchools, numberOfSubmissions } =
    followup

  const {
    tasks,
    minEndDate,
    maxEndDate,
    formMethods,
    openPreview,
    taskCriteria,
    selectedTaskId,
    setTaskCriteria,
    criteriaOptions,
    isTaskPreviewOpen,
    onTaskPreviewClose,
    handleGoToStatistics,
    tasksSelected,
  } = useFollowupDetails({ followupId: id })

  console.log('id',id)

  const { control, register, watch, setValue } = formMethods
  const { t, i18n } = useTranslation()
  const [selectedId, setSelectedId] = useState<string[]>([])
  const [taskOrder, setTaskOrder] = useState(tasks)
  const location = useLocation()
  const { isOpen, onToggle } = useDisclosure()

  const [isChecked,setIsChecked] = useState(false)

  const isInPath = location.pathname === '/followup/create'
  const onDragEnd = result => {
    if (!result.destination) return

    const reorderedTasks = Array.from(taskOrder)
    const [movedTask] = reorderedTasks.splice(result.source.index, 1)
    reorderedTasks.splice(result.destination.index, 0, movedTask)

    setTaskOrder(reorderedTasks)
  }

  const handleTaskSelectionChange = (taskId: string, isSelected: boolean) => {
    setSelectedId(prevSelectedId => {
      if (isSelected) {
        // If the checkbox is checked, add the taskId to the array if it doesn't exist
        if (!prevSelectedId.includes(taskId)) {
          //  moveTaskToTop(taskId)
          return [...prevSelectedId, taskId]
        } else {
          // If the taskId already exists, return prevSelectedId without modification
          return prevSelectedId
        }
      } else {
        // If the checkbox is unchecked, remove the taskId from the array
        // moveTaskToBottom(taskId)
        return prevSelectedId.filter(id => id !== taskId)
      }
    })
  }

  // Options for the learning areas with translations for each language
  const skillAreaOptions = [
    { label: t('Learning_Skills'), value: 'learning_skills' },
    { label: t('Language_Development'), value: 'language_development' },
    { label: t('Mathematics'), value: 'mathematics' },
    { label: t('Natural_Science'), value: 'natural_science' },
  ]
  // State to track the selected skill area (filter)
  const [filter, setFilter] = useState({ skillArea: '' })
  // Get the appropriate label based on the selected language
  const getLabel = option => option.label 
  // onChange function to update the selected skill area
  const onSkillAreaChange = selectedOption => {
    // Update the filter state
    setFilter(prevFilter => ({
      ...prevFilter,
      skillArea: selectedOption ? selectedOption.label : '', // Set empty string when cleared
    }))

    // Apply filtering logic or reset the task list if the selection is cleared
    if (selectedOption) {
      // Filter tasks based on the selected skill area
      let filteredTasks = tasks.filter(
        task => task.skillArea === selectedOption.label,
      )
      setTaskOrder(filteredTasks)
    } else {
      // Reset the task list when the selection is cleared
      setTaskOrder(tasks) 
    }
  }

  useEffect(() => {
    //check for sessionstorage and show task list as per the language analysis value.
    setTimeout(() => {
      let filteredTasks = tasks
      if (sessionStorage.getItem('languageAnalysis') !== 'true') {
        filteredTasks = tasks.filter(task => task.type !== 'language_analysis')
      }

      setTaskOrder(filteredTasks)
      //  setTempTaskOrder(filteredTasks)
    }, 1000)
  }, [tasks])

  useEffect(() => {
    setValue('taskIds', selectedId)
  }, [selectedId])

  const handleShowSelectedTasks = event => {
    if (event.target.checked) {
      onSkillAreaChange(null)
      setIsChecked(true)
      const filteredTasks = tasks.filter(task => selectedId.includes(task.id))
      setTaskOrder(filteredTasks)
    } else {
      setTaskOrder(tasks)
      setIsChecked(false)
    }
  }

  return (
    <VStack w='full'>
      <Box bgColor='#fff' h='100%' style={{ fontFamily: 'Open Sans' }} w='full'>
        {id && (
          <HStack spacing={6} pb={10}>
            <StatsContainer
              loading={false}
              name={t('DashboardOverview_Number_of_Preschools')}
              count={numberOfSchools}
              imageSrc='/images/orangeIcon.png'
            />
            <StatsContainer
              loading={false}
              name={t('DashboardOverview_Number_Completed_FollowUps')}
              count={numberOfSubmissions}
              imageSrc='/images/blueIcon.png'
            />
            <StatsContainer
              loading={false}
              name={t('Num_Participants')}
              count={numberOfParticipants}
              imageSrc='/images/blueIconPeople.png'
            />
            {followup.status !== FollowupStatus.DRAFT && (
              <Flex justifySelf='end' h='fit-content' w='fit-content'>
                <VStack
                  p='3'
                  height='90px'
                  width='100px'
                  borderWidth={1}
                  borderRadius='8px'
                  borderColor='#3443D5'
                  cursor='pointer'
                  _hover={{
                    backgroundColor: '#dee4f2',
                    opacity: 0.9,
                  }}
                  onClick={handleGoToStatistics}
                >
                  <StatisticsIcon />
                  <Text textAlign='center' color='#3443D5' fontWeight='bold'>
                    {t('Go_To_Statistics')}
                  </Text>
                </VStack>
              </Flex>
            )}
          </HStack>
        )}

        <HStack w='full'>
          <Grid
            w='full'
            gridTemplateColumns='3fr 1.5fr 1.5fr 1.5fr 1fr'
            gridGap={6}
          >
            <GridItem>
              <FormWrapper name='name' label={t('Name')} isRequired>
                <Input
                  type='text'
                  placeholder={t('Follow_up_name')}
                  _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                  focusBorderColor=''
                  fontSize='14px'
                  bgColor='white'
                  color='#1A2C3D'
                  border='1px'
                  borderColor='#B1C5D9'
                  h='40px'
                  w='full'
                  borderRadius='lg'
                  isDisabled={!isEditable}
                  {...register('name')}
                  paddingLeft='2'
                />
              </FormWrapper>
            </GridItem>

            <GridItem>
              <FormWrapper
                name='startDate'
                label={t('FollowupTable_Start_date')}
                isRequired
              >
                <Controller
                  control={control}
                  name='startDate'
                  render={({ field: { onChange, value } }) => (
                    <div
                      className='position-relative'
                      style={{ backgroundColor: 'white' }}
                    >
                      <label>
                        <div>
                          <DatePicker
                            dateFormat='yyyy-MM-dd'
                            selected={value}
                            autoComplete='off'
                            className='date-picker'
                            minDate={new Date()}
                            // onChange={onChange}
                            onChange={date => {
                              onChange(date || ''); // Fallback to an empty string if date is null
                            }}
                            disabled={!isEditable}
                          />
                        </div>
                        <div className='cal-icon'>
                          <Image src={calendarIcon} />
                        </div>
                      </label>
                    </div>
                  )}
                />
              </FormWrapper>
            </GridItem>

            <GridItem>
              <FormWrapper
                name='endDate'
                label={t('FollowupTable_End_date')}
                isRequired
              >
                <Controller
                  control={control}
                  name='endDate'
                  render={({ field: { onChange, value } }) => (
                    <div
                      className='position-relative'
                      style={{ backgroundColor: 'white' }}
                    >
                      <label>
                        <div>
                          <DatePicker
                            dateFormat='yyyy-MM-dd'
                            selected={value}
                            autoComplete='off'
                            className='date-picker'
                            minDate={isEditEndDate ?  new Date() : minEndDate}
                            maxDate={maxEndDate}
                            // onChange={e => {
                            //   onChange(e)
                            // }}
                            onChange={date => {
                              onChange(date || ''); // Fallback to an empty string if date is null
                            }}
                            disabled={!isEditable && !isEditEndDate}
                          />
                        </div>
                        <div className='cal-icon'>
                          <Image src={calendarIcon} />
                        </div>
                      </label>
                    </div>
                  )}     
                />
              </FormWrapper>
            </GridItem>

            <GridItem>
              <FormWrapper
                name='academicYear'
                label={t('FollowupFilter_School_year')}
              >
                <Input
                  h='40px'
                  w='full'
                  isDisabled
                  type='text'
                  border='1px'
                  fontSize='14px'
                  bgColor='white'
                  color='#1A2C3D'
                  borderRadius='lg'
                  focusBorderColor=''
                  borderColor='#B1C5D9'
                  placeholder={t('Academic_Year_Interval')}
                  _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
                  {...register('academicYear')}
                  paddingLeft='2'
                />
              </FormWrapper>
            </GridItem>
            <GridItem
              display={'flex'}
              alignItems={'center'}
              justifyContent={'left'}
            >
              <HStack spacing={10} w='full' justify='flex-start' align='center'>
                <Controller
                  control={control}
                  name='isExportable'
                  render={({ field: { onChange, value } }) => (
                    <CheckboxIsExportable
                      isExportable={value}
                      disabled={!isEditable}
                      handleOnChange={onChange}
                    />
                  )}
                />
              </HStack>
            </GridItem>
          </Grid>
        </HStack>

        <VStack py={8} w='full'>
          <Flex w='full'>
            <FormWrapper name='description' label={t('Description')}>
              <Textarea
                placeholder={t('Description_FollowUp')}
                fontSize='14px'
                bgColor={'white'}
                color={'#1A2C3D'}
                _placeholder={{ color: 'black' }}
                border='1px'
                borderColor='#B1C5D9'
                h={'40px'}
                w={'100%'}
                borderRadius={'8px'}
                isDisabled={!isEditable}
                {...register('description')}
                paddingLeft='2'
              />
            </FormWrapper>
          </Flex>

          <Controller
            control={control}
            name='taskIds'
            render={({ field: { onChange, value } }) => (
              <VStack w='full'>
                <Flex py={8} pb={0} w='full'>
                  <HStack spacing='30%' w='full' justify={'flex-start'}>
                    <VStack w='30%'>
                      <MessageFormWrapper
                        className='followup-details-bold'
                        name='taskIds'
                        // label={` ${t('Task_List')} (${value?.length} of 5) ${
                        //   value?.length > 1 ? t('Tasks') : t('Overview_Task')
                        // }`}
                        label={` ${t('Task_List')} (${value?.length} / 5) 
                        `}
                        specificErrorMessage={t('Please_select_the_tasks')}
                      />
                      {tasksSelected?.length >= 5 && isInPath && (
                        <Text
                          w='full'
                          textAlign='start'
                          name='taskIds'
                          fontSize='sm'
                          color='#FF6700'
                        >
                          {t('More_Than_5_Tasks')}
                        </Text>
                      )}
                    </VStack>
                    <HStack w='70%'>
                      {isEditable && (
                        <>
                        <Box w='50%'>

                        
                          <CustomSelect
                            isClearable={true}
                            name='skillArea'
                            className='bold-value'
                            placeholder={t('Select_Learning_Area')}
                            value={
                              skillAreaOptions.find(
                                option => option.label === filter.skillArea,
                              ) || null
                            }
                            options={skillAreaOptions.map(option => ({
                              label: getLabel(option),
                              value: option.value,
                            }))}
                            valueKey='value'
                            minWidth='200px'
                            onChange={onSkillAreaChange}
                            isDisabled = {isChecked ? true : false}

                          />
</Box>
                          <Box w='50%' textAlign={'right'} className='show-selected'>
                            <Checkbox
                              size='md'
                             
                              onChange={e => handleShowSelectedTasks(e)}
                              isDisabled={selectedId.length > 0 ? false : true}
                           boxShadow={'none !important'}
                            outline={'none'}
                              
                            >
                              {t('Show_Selected_Tasks')}
                            </Checkbox>
                          </Box>
                        </>
                      )}

                      {isEditable && (
                        <Box
                          className='help-task'
                          w={'50%'}
                          textAlign={'right'}
                        >
                          <TaskHelpButtonComponent
                            onToggle={onToggle}
                            isOpen={isOpen}
                            textTitle={t('Help_Select_Task')}
                          />
                        </Box>
                      )}
                    </HStack>

                    {/* <Box
                      style={{
                        paddingRight: '30px',
                        width: '40%',
                        height: '40px',
                        backgroundColor: 'white',
                        border: '1px',
                        borderColor: '#B1C5D9',
                        borderRadius: '8px',
                      }}
                    >
                    
                    </Box> */}
                  </HStack>
                </Flex>

                <Box>
                  <TaskHelpComponent
                    helpDetailText={t('Task_List')}
                    isOpen={isOpen}
                  />
                </Box>

                <Box flex={10} bg='white' w={'full'}>
                  <TableContainer borderRadius='10px'>
                    {/* <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId='droppable'>
                        {provided => ( */}
                    <Table
                      variant='simple'
                      // ref={provided.innerRef}
                      // {...provided.droppableProps}
                    >
                      <Thead position='sticky'>
                        <Tr
                          bgColor={'rgba(177, 197, 217, 0.5)'}
                          borderWidth='1px'
                          borderRadius='10px'
                          height='48px'
                          fontWeight={'bold'}
                          fontFamily={`'Open Sans',sans-serif`}
                          fontSize={'12px'}
                        >
                          {isEditable && <Th></Th>}
                          <Th>{t('Overview_Task')}</Th>
                          <Th>{t('Name')}</Th>

                          <Th>{t('Skill_Area')}</Th>
                          {/* <Th>{t('Skill_Subarea')}</Th> */}
                          <Th>{t('Knowledge_Text')}</Th>
                          <Th>{t('Skill_Text')}</Th>
                          <Th>{t('Task_Type_Text')}</Th>
                          <Th>{t('Response_type')}</Th>
                        </Tr>
                      </Thead>

                      <Tbody>
                        {taskOrder
                          // .filter(task =>
                          //   taskCriteria?.value
                          //     ? task.criteria === taskCriteria.value
                          //     : true,
                          // )
                          // .filter(task =>
                          //   isEditable ? true : value?.includes(task.id),
                          // )
                          // .sort((a, b) => {
                          //   const skillAreaA = a.skillArea ?? '';
                          //   const skillAreaB = b.skillArea ?? '';
                          //   return skillAreaA.localeCompare(skillAreaB);
                          // })
                          .sort((a, b) => {
                            const skillAreaA = a.skillArea ?? ''
                            const skillAreaB = b.skillArea ?? ''

                            // Prioritize selected tasks at the top
                            // if (
                            //   selectedId.includes(a.id) &&
                            //   !selectedId.includes(b.id)
                            // )
                            //   return -1
                            // if (
                            //   !selectedId.includes(a.id) &&
                            //   selectedId.includes(b.id)
                            // )
                            //   return 1

                            // Then sort by skill area
                            return skillAreaA.localeCompare(skillAreaB)
                          })

                          .filter(task =>
                            isEditable ? true : value?.includes(task.id),
                          )
                          .map((task, index) => (
                            <React.Fragment key={task.id}>
                              {selectedId.includes(task.id) ? (
                                // <Draggable
                                //   key={task.id}
                                //   draggableId={task.id}
                                //   index={index}
                                // >
                                //   {provided => (
                                <Tr
                                  key={task.id}
                                  // ref={provided.innerRef}
                                  // {...provided.draggableProps}
                                  // {...provided.dragHandleProps}
                                >
                                  {isEditable && (
                                    <Td>
                                      <div
                                        className='checkbox'
                                        id='cb'
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {/* <HamburgerIcon justifyContent={'center'} marginRight={4} w={18} h={18} color='grey' /> */}
                                        {/* {selectedId.includes(task.id) && <HamburgerIcon justifyContent={'center'} marginRight={4} w={18} h={18} color='grey' />} */}
                                        {/* {selectedId.includes(
                                                    task.id,
                                                  ) && (
                                                    <Image
                                                      marginRight={4}
                                                      src={iconsort}
                                                    />
                                                  )} */}
                                        <input
                                          type='checkbox'
                                          name='checkbox'
                                          checked={selectedId.includes(task.id)}
                                          style={{
                                            width: '18px',
                                            height: '18px',
                                            accentColor: '#3443D5',
                                          }}
                                          disabled={
                                            value?.length > 5 &&
                                            !value?.includes(task.id)
                                          }
                                          defaultChecked={value?.includes(
                                            task.id,
                                          )}
                                          onChange={(e: any) => {
                                            const { checked } = e.target

                                            let newSelectedTasks = value || []

                                            if (checked) {
                                              newSelectedTasks.push(task.id)

                                              // moveTaskToTop(task.id)
                                            }

                                            if (!checked) {
                                              newSelectedTasks =
                                                newSelectedTasks.filter(
                                                  (taskId: string) =>
                                                    taskId != task.id,
                                                )

                                              // moveTaskToBottom(task.id)
                                            }
                                            setSelectedId(newSelectedTasks)

                                            onChange(selectedId)
                                          }}
                                        ></input>
                                      </div>
                                    </Td>
                                  )}

                                  <Td
                                    maxW='600px'
                                    id={`${task.id}`}
                                    onClick={(q: any) => {
                                      openPreview(q.target.id)
                                    }}
                                    style={{
                                      cursor: 'pointer',
                                      color: 'blue',
                                      whiteSpace: 'pre-wrap',
                                    }}
                                  >
                                    {task.type === 'video_multi' && (
                                      <>
                                        {Object.values(task.title)[0] as string}
                                        <br />
                                        {Object.values(task.title)[1] as string}
                                      </>
                                    )}
                                    {task.type !== 'video_multi' && task.title}
                                  </Td>
                                  <Td>{task.name}</Td>
                                  <Td>{task.skillArea}</Td>
                                  {/* <Td>{task.skillSubarea}</Td> */}
                                  <Td>{task.knowledge}</Td>
                                  <Td
                                    style={{
                                      whiteSpace: 'pre-wrap',
                                      maxWidth: '200px',
                                    }}
                                  >
                                    {task.criteria}
                                  </Td>
                                  <Td>
                                    <Tag
                                      borderRadius='full'
                                      variant='outline'
                                      colorScheme='gray'
                                      bg={'#F4F4F4'}
                                    >
                                      <TagLabel color={'blackAlpha.900'}>
                                        {task.id ===
                                          '8d4dda81-a4de-4435-a8cc-43a9888c44c6' ||
                                        task.id ===
                                          'b4d26945-4c02-4089-a2b5-1bea3c08018c' ||
                                        task.id ===
                                          '56ed0cd2-1852-49f6-94e5-89f0c5601fa5'
                                          ? i18n.language === 'se' ||
                                            i18n.language === 'de'
                                            ? 'Bild'
                                            : 'Picture'
                                          : i18n.language === 'se'
                                          ? TaskTypeLabel[
                                              task.type as keyof typeof TaskTypeLabel
                                            ]
                                          : TaskTypeLabelEn[
                                              task.type as keyof typeof TaskTypeLabelEn
                                            ]}
                                      </TagLabel>
                                    </Tag>
                                  </Td>
                                  <Td>
                                    {task.id ===
                                      '32133305-db19-43fa-96c8-54aca833712f' ||
                                    task.id ===
                                      '77d78b34-ae87-4be5-8d31-151e42f44cd7' ||
                                    task.id ===
                                      'b4d26945-4c02-4089-a2b5-1bea3c08018c'
                                      ? i18n.language === 'se'
                                        ? 'Icke-verbal'
                                        : 'Non-verbal'
                                      : i18n.language === 'se'
                                      ? ResponseTypeLabel[
                                          task.type as unknown as keyof typeof ResponseTypeLabel
                                        ]
                                      : ResponseTypeLabelEn[
                                          task.type as unknown as keyof typeof ResponseTypeLabelEn
                                        ]}
                                  </Td>
                                </Tr>
                              ) : (
                                //   )}
                                // </Draggable>
                                <Tr key={task.id}>
                                  {isEditable && (
                                    <Td>
                                      <div className='checkbox' id='cb'>
                                        <input
                                          type='checkbox'
                                          name='checkbox'
                                          style={{
                                            width: '18px',
                                            height: '18px',
                                            accentColor: '#3443D5',
                                          }}
                                          disabled={
                                            value?.length >= 5 &&
                                            !value?.includes(task.id)
                                          }
                                          checked={value?.includes(task.id)} // Use checked instead of defaultChecked
                                          onChange={(e: any) => {
                                            const { checked } = e.target

                                            let newSelectedTasks = value
                                              ? [...value]
                                              : [] // Create a new array

                                            if (checked) {
                                              newSelectedTasks.push(task.id)

                                              // moveTaskToTop(task.id)
                                            } else {
                                              newSelectedTasks =
                                                newSelectedTasks.filter(
                                                  taskId => taskId !== task.id,
                                                )
                                              // moveTaskToBottom(task.id)
                                            }
                                            setSelectedId(newSelectedTasks)
                                            onChange(newSelectedTasks)
                                          }}
                                        ></input>
                                      </div>
                                    </Td>
                                  )}

                                  <Td
                                    maxW='600px'
                                    id={`${task.id}`}
                                    onClick={(q: any) => {
                                      openPreview(q.target.id)
                                    }}
                                    style={{
                                      cursor: 'pointer',
                                      color: 'blue',
                                      whiteSpace: 'pre-wrap',
                                    }}
                                  >
                                    {task.type === 'video_multi' && (
                                      <>
                                        {Object.values(task.title)[0] as string}
                                        <br />
                                        {Object.values(task.title)[1] as string}
                                      </>
                                    )}
                                    {task.type !== 'video_multi' && task.title}
                                  </Td>
                                  <Td>{task.name}</Td>

                                  <Td>{task.skillArea}</Td>
                                  {/* <Td>{task.skillSubarea}</Td> */}
                                  <Td>{task.knowledge}</Td>
                                  <Td
                                    style={{
                                      whiteSpace: 'pre-wrap',
                                      maxWidth: '200px',
                                    }}
                                  >
                                    {task.criteria}
                                  </Td>
                                  <Td>
                                    <Tag
                                      borderRadius='full'
                                      variant='outline'
                                      colorScheme='gray'
                                      bg={'#F4F4F4'}
                                    >
                                      <TagLabel color={'blackAlpha.900'}>
                                        {task.id ===
                                          '8d4dda81-a4de-4435-a8cc-43a9888c44c6' ||
                                        task.id ===
                                          'b4d26945-4c02-4089-a2b5-1bea3c08018c' ||
                                        task.id ===
                                          '56ed0cd2-1852-49f6-94e5-89f0c5601fa5'
                                          ? i18n.language === 'en'
                                            ? 'Picture'
                                            : 'Bild'
                                          : i18n.language === 'se'
                                          ? TaskTypeLabel[
                                              task.type as keyof typeof TaskTypeLabel
                                            ]
                                          : TaskTypeLabelEn[
                                              task.type as keyof typeof TaskTypeLabelEn
                                            ]}
                                      </TagLabel>
                                    </Tag>
                                  </Td>
                                  <Td>
                                    {task.id ===
                                      '32133305-db19-43fa-96c8-54aca833712f' ||
                                    task.id ===
                                      '77d78b34-ae87-4be5-8d31-151e42f44cd7' ||
                                    task.id ===
                                      'b4d26945-4c02-4089-a2b5-1bea3c08018c'
                                      ? i18n.language === 'se'
                                        ? 'Icke-verbal'
                                        : 'Non-verbal'
                                      : i18n.language === 'se'
                                      ? ResponseTypeLabel[
                                          task.type as unknown as keyof typeof ResponseTypeLabel
                                        ]
                                      : ResponseTypeLabelEn[
                                          task.type as unknown as keyof typeof ResponseTypeLabelEn
                                        ]}
                                  </Td>
                                </Tr>
                              )}
                            </React.Fragment>
                          ))}
                        {/* {provided.placeholder} */}
                      </Tbody>
                    </Table>
                    {/* )}
                      </Droppable>
                    </DragDropContext> */}
                  </TableContainer>
                </Box>
                {tasks.length < 3 && (
                  <div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                  </div>
                )}

                <TaskPreview
                  isOpen={isTaskPreviewOpen}
                  onClose={onTaskPreviewClose}
                  taskId={selectedTaskId}
                  selectedId={selectedId}
                  taskOrder={taskOrder}
                  isInPath={isInPath}
                  onTaskSelectionChange={handleTaskSelectionChange}
                />
              </VStack>
            )}
          />
        </VStack>
      </Box>
    </VStack>
  )
}
