import React, { forwardRef } from 'react'
import { useFollowupDetails } from './hooks'
import { DndProvider, Preview } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import ImageSortingTaskCmp from 'pages/MainTabs/OverviewTab/TaskPreview/ImageSorting/ImageSortingTask'
import ImageSelectionTaskCmp from 'pages/MainTabs/OverviewTab/TaskPreview/ImageSelectionTask'
import CheckboxTaskCmp from 'pages/MainTabs/OverviewTab/TaskPreview/CheckboxTask'
import ImageClickTaskCmp from 'pages/MainTabs/OverviewTab/TaskPreview/ImageClickTaskCmp'
import ImagePlacementTaskCmp from 'pages/MainTabs/OverviewTab/TaskPreview/ImagePlacement/ImagePlacementTask'
import LanguageTask from 'pages/MainTabs/OverviewTab/TaskPreview/LanguageAnalysis/LanguageTask'
import VideoMultiTaskCmp from 'pages/MainTabs/OverviewTab/TaskPreview/VideoTask/VideoMultiTask'
import VideoTaskCmp from 'pages/MainTabs/OverviewTab/TaskPreview/VideoTask/VideoTask'
import { Image,Text } from '@chakra-ui/react'

interface PrintDocProps {
  followupId: string
  followupName: string
}

const PrintDoc = forwardRef<HTMLDivElement, PrintDocProps>(
  ({ followupId,followupName }, ref) => {
    const selectedTasksArray: any[] = []
    const { tasksSelected, tasks } = useFollowupDetails({
      followupId: followupId,
    })

    tasksSelected.forEach(item => {
      const matchedTasks = tasks.find(task => task.id === item)

      if (matchedTasks) {
        selectedTasksArray.push(matchedTasks)
      }
    })
    const generatePreview = ({ itemType: _itemType, item, style }: any) => {
        return <Image src={item.image} style={{ ...style, opacity: 0.5 }} />
      }

    selectedTasksArray.sort((a, b) => a.skillArea.localeCompare(b.skillArea, 'sv'))  
    
    return (
      <div ref={ref} className="print-only">
        {/* <Text p='10' fontStyle={'bold'} >{followupName}</Text> */}
                {selectedTasksArray.map(task => (
            <div key={task.id} className="task-page">
              {(() => {
                switch (task.type) {
                  case 'image_selection':
                    return <ImageSelectionTaskCmp key={task.id} task={task} />
                  case 'checkbox_selection':
                    return <CheckboxTaskCmp key={task.id} task={task} />
                  case 'video':
                    return <VideoTaskCmp key={task.id} task={task} />
                  case 'video_multi':
                    return <VideoMultiTaskCmp key={task.id} task={task} />
                  case 'image_placement':
                    return (
                      <DndProvider options={HTML5toTouch}>
                        <ImagePlacementTaskCmp key={task.id} task={task} />
                        <Preview generator={generatePreview} />
                      </DndProvider>
                    )
                  case 'image_sorting':
                    return (
                      <DndProvider options={HTML5toTouch}>
                        <ImageSortingTaskCmp key={task.id} task={task} />
                        <Preview generator={generatePreview} />
                      </DndProvider>
                    )
                  case 'language_analysis':
                    return <LanguageTask key={task.id} task={task} />
                  case 'image_click':
                    return <ImageClickTaskCmp key={task.id} task={task} />
                  default:
                    return <p key={task.id}>Task type: {task.type}</p>
                }
              })()}
            </div>
          ))}

      </div>
    )
  },
)
export default PrintDoc
