import React from 'react'
import {
  Text,
  Grid,
  Input,
  HStack,
  GridItem,
  SimpleGrid,
  Button,
  Flex,
} from '@chakra-ui/react'
import { useUserFilter } from '../hooks'
import { UsersFilterType } from '../UsersFilterType'
import CustomSelect, { ISelectOption } from 'common/CustomSelect'
import debounce from 'lodash.debounce'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUser } from 'providers/UserProvider'

interface UserFilterProps {
  filter: UsersFilterType
  onFilterChange: (newFilter: Partial<UsersFilterType>) => void
}

export const UserFilter = ({ filter, onFilterChange }: UserFilterProps) => {
  const { t, i18n } = useTranslation()
  const { preschoolOptions, roleOptions, isAdmin } = useUserFilter()
  const history = useHistory()
  const roleOptionsModified = [
    { value: '', label: i18n.language === 'se' ? 'Alla' : i18n.language === 'de' ? 'Alle' : 'All'   },
    ...roleOptions,
  ]

  const handleFilterChange = (
    filterKey: keyof UsersFilterType,
    newVal: any,
  ) => {
    onFilterChange({ [filterKey]: newVal })
  }

  const handleQueryChange = e => {
    handleFilterChange('name', e.target.value)
  }

  const onPreschoolChange = (
    preschools: { value: string; label: string }[],
  ) => {
    handleFilterChange(
      'preschoolId',
      preschools ? preschools.map(preschool => preschool.value) : [],
    )
  }
  const usr = useUser()
  const org = parseInt(usr.user.organizationId)
  const onCreateUserOpen = () => {
    history.push(`/user/create`)
  }
  const onRegisterUserOpen = () => {
    history.push(`/user/invite`)
  }
  return (
    <HStack w='full' h='full' justifyContent={'space-between'}>
      <Grid
        w='full'
        h='full'
        gridGap={3}
        gridTemplateColumns={'repeat(auto-fill, 300px)'}
      >
        <GridItem>
          <SimpleGrid columns={1} row={2} gap={0}>
            <Text
              width={'100%'}
              height={'20px'}
              fontSize='12px'
              color='#5A738B'
            >
              {t('User_Name')}{' '}
            </Text>
            <Input
              type='search'
              placeholder={t('Search')}
              name='searchUser'
              _placeholder={{ color: 'rgba(0, 0, 0, 900)' }}
              focusBorderColor=''
              fontSize='14px'
              bgColor={'white'}
              color={'#1A2C3D'}
              border='1px'
              borderColor='#B1C5D9'
              h={'40px'}
              borderRadius={'8px'}
              w='full'
              onChange={debounce(handleQueryChange, 400)}
            />
          </SimpleGrid>
        </GridItem>
        <GridItem>
          <SimpleGrid columns={1} row={2} gap={0}>
            <Text
              width={'100%'}
              height={'20px'}
              fontSize='12px'
              color='#5A738B'
            >
              {t('ResultFilter_Preschool')}
            </Text>
            <CustomSelect
              name='preschoolId'
              value={preschoolOptions.filter(preschool =>
                filter.preschoolId.includes(preschool.value!),
              )}
              options={preschoolOptions.sort((a, b) =>a.label.localeCompare(b.label, 'sv'))}
              onChange={onPreschoolChange}
              className='bold-value'
              searchable={true}
              isMulti={true}
              placeholder={t('ResultFilter_Choose_Preschool')}
            />
          </SimpleGrid>
        </GridItem>
        <GridItem>
          <SimpleGrid columns={1} row={2} gap={0}>
            <Text
              width={'100%'}
              height={'20px'}
              fontSize='12px'
              color='#5A738B'
            >
              {t('Role_Text')}
            </Text>
            <CustomSelect
              name='organizationId'
              value={roleOptionsModified.find(
                roleOption => roleOption.value === filter.role,
              )}
              options={roleOptionsModified}
              onChange={(option: ISelectOption) =>
                handleFilterChange('role', option.value)
              }
              className='bold-value'
              searchable={true}
              isMulti={false}
              placeholder={t('Select_Role')}
            />
          </SimpleGrid>
        </GridItem>
      </Grid>
      {isAdmin && (
        <Flex direction='column'>
          {org !== 1287 ? (
            <Button
              mb='2'
              minW='200px'
              height='35px'
              fontSize={'sm'}
              onClick={onCreateUserOpen}
            >
              {t('Add_User')}
            </Button>
          ) : (
            <Button
              mb='2'
              minW='150spx'
              height='35px'
              fontSize={'sm'}
              onClick={onRegisterUserOpen}
            >
              {t('Register_user')}
            </Button>
          )}
        </Flex>
      )}
    </HStack>
  )
}
