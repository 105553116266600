import React, { useState } from 'react'

import {
  Tr,
  Td,
  Box,
  Flex,
  Table,
  Thead,
  Spinner,
  useDisclosure,
  TableContainer,
} from '@chakra-ui/react'

import { CommentsFilterType } from '../types'
import { CommentModal } from 'common/CommentModal'
import { Pagination } from 'common/Pagination/Pagination'
import { IndividualCommentsResponse } from 'data-models/comment'
import { useTranslation } from 'react-i18next'

interface CommentsTableProps {
  loading: boolean
  response: IndividualCommentsResponse
  filter: CommentsFilterType
  onFilterChange: (filter: Partial<CommentsFilterType>) => void
}

export const CommentsTable = ({
  filter,
  loading,
  response,
  onFilterChange,
}: CommentsTableProps) => {
  const { data, totalCount } = response
  const { t,i18n } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedComment, setSelectedComment] = useState('')

  const handleCommentOnClick = (comment: string) => {
    if (comment) {
      setSelectedComment(comment)
      onOpen()
    }
  }

  
  const handlePageSizeChange = (newSize: number) => {
    onFilterChange({ pageSize: newSize })
  }

  const handlePageNumberChange = (newIndex: number) => {
    onFilterChange({ pageNumber: newIndex })
  }



  type Result = {
    childBirthDate: string;
  };
  
  const formatDate = (dateString: string): string => {
    const options: Intl.DateTimeFormatOptions = { 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric' 
    };
    const date = new Date(dateString);
  
    const formattedDate = i18n.language === 'se'
    ? new Intl.DateTimeFormat('sv-SE', options).format(date)
    : i18n.language === 'de'
    ? new Intl.DateTimeFormat('de-DE', options).format(date)
    : new Intl.DateTimeFormat('en-US', options).format(date);
  
    if (i18n.language !== 'en') {
      // Split the formatted date into parts
      const dateParts = formattedDate.split(' ');
  
      // Capitalize the month part (which is the second part in the Swedish format)
      if (dateParts.length > 1) {
        dateParts[1] = dateParts[1].charAt(0).toUpperCase() + dateParts[1].slice(1);
      }
  
      // Reassemble the date string
      return dateParts.join(' ');
    }
  
    return formattedDate;
  };
  return (
    <>
      {loading ? (
        <Flex pt='20' justifyContent={'center'} w='full' h='full'>
          <Spinner size='lg' />
        </Flex>
      ) : (
        <>
          <TableContainer w='full' borderRadius='md'>
            <Table
              // __css={{ 'table-layout': 'fixed' }}
              variant='simple'
              resize={'none'}
              w='full'
            >
              <Thead>
                <Tr
                  bgColor={'rgba(177, 197, 217, 0.5)'}
                  borderWidth='1px'
                  borderRadius='10px'
                  height='48px'
                  fontWeight={'bold'}
                  fontFamily={`'Open Sans',sans-serif`}
                  fontSize={'12px'}
                >
                  <Td>{t('CommentsTable_Date')}</Td>
                  <Td>{t('MainHeader_FollowUp')}</Td>
                  <Td>{t('Overview_Comments')}</Td>
                  <Td>{t('Login_Email_PlaceHolder')}</Td>
                  {/* <Td>{t('CommentsTable_Child_DOB')}</Td> */}
                </Tr>
              </Thead>

              {data.map(result => (
                <Tr
                  fontWeight={'light'}
                  fontFamily={`'Open Sans',sans-serif`}
                  fontSize={'14px'}
                  key={result.id}
                  textOverflow={'ellipsis'}
                  onClick={() => handleCommentOnClick(result.comment)}
                  _hover={{ backgroundColor: '#EFF1FF' }}
                  cursor='pointer'
                >
                  <Td
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      // maxWidth: '200px',
                      overflow: 'hidden',
                    }}
                  >
                    {result.submittedAt?.split('T')[0]}
                  </Td>
                  <Td
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      // maxWidth: '200px',
                      overflow: 'hidden',
                    }}
                  >
                    {result.followupName}
                  </Td>
                  <Td
                   
                    _hover={{ backgroundColor: '#EFF1FF' }}
                    cursor='pointer'
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '200px',
                      overflow: 'hidden',
                    }}
                  >
                    <span
                      style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '200px',
                        overflow: 'hidden',
                      }}
                    >
                      {result.comment}
                    </span>
                  </Td>
                  <Td
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      // maxWidth: '250px',
                      overflow: 'hidden',
                    }}
                  >
                    {result.email}
                  </Td>
                  {/* <Td
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                   {formatDate(result.childBirthDate)}
                  </Td> */}
                </Tr>
              ))}
            </Table>
            <CommentModal
              comment={selectedComment}
              data={data}
              isOpen={isOpen}
              onClose={onClose}
            />
          </TableContainer>
          {(!data || data?.length === 0) && (
            <Box style={{ height: '60px' }}>
              <h1 style={{ textAlign: 'center', paddingTop: '20px' }}>
                {t('ResultsTable_No_Data')}
              </h1>
            </Box>
          )}
          {totalCount > 10 && (
            <Pagination
              siblingCount={1}
              totalCount={totalCount}
              settings={{
                pageNumber: filter.pageNumber,
                pageSize: filter.pageSize,
              }}
              onPageNumberChange={handlePageNumberChange}
              onPageSizeChange={handlePageSizeChange}
            />
          )}
        </>
      )}
    </>
  )
}
